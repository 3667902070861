import { useEffect, useRef } from 'react'
import { Image } from 'src/styled'

const LazyImage = ({ src, ...props }) => {
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target
          const src = img.getAttribute('data-src')
          img.setAttribute('src', src)
          observer.disconnect()
        }
      })
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return <Image ref={ref} src={src} data-src={src} {...props} />
}

export default LazyImage
