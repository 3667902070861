import { dates, time } from 'src/modules/auth/constants'
import isEng from 'src/utils/inEnglish.ts'

export const getDayOfPublication = (createdAt: number, locales: any) => {
  const date = new Date(createdAt).valueOf()
  const now = new Date().valueOf()

  const twentyFourHourInMS = 8.64e7
  const floorDayOfPublication = Math.floor((now - date) / twentyFourHourInMS)

  const {
    day: { periodDay, descriptionDay },
    week: { periodWeek, descriptionWeek },
    month: { periodMonth, descriptionMonth },
  } = dates(locales, floorDayOfPublication)

  const { new: keywordNew, ago: keywordAgo } = time(locales)

  if (floorDayOfPublication >= periodMonth)
    return computePublicationDate(
      floorDayOfPublication,
      periodMonth,
      descriptionMonth,
      keywordAgo,
      locales,
    )

  if (floorDayOfPublication >= periodWeek)
    return computePublicationDate(
      floorDayOfPublication,
      periodWeek,
      descriptionWeek,
      keywordAgo,
      locales,
    )

  if (!floorDayOfPublication) return keywordNew

  return computePublicationDate(
    floorDayOfPublication,
    periodDay,
    descriptionDay,
    keywordAgo,
    locales,
  )
}

const computePublicationDate = (
  difference: number,
  period: number,
  periodName: string,
  ago: any,
  locales: any,
) => {
  const isEnglish = isEng(locales)
  const floorPeriod = Math.floor(difference / period)
  const isPluralform = isEnglish && floorPeriod > 1 ? 's' : ''
  return floorPeriod > 1
    ? `${floorPeriod} ${periodName}${isPluralform} ${ago}`
    : `${floorPeriod} ${periodName} ${ago}`
}
