import styled from 'styled-components'
import { ComponentType } from 'react'
import {
  color,
  layout,
  space,
  size,
  typography,
  border,
  position,
  ColorProps,
  LayoutProps,
  SpaceProps,
  SizeProps,
  TypographyProps,
  BorderProps,
  PositionProps,
} from 'styled-system'

export interface SpanProps
  extends ColorProps,
    LayoutProps,
    SpaceProps,
    SizeProps,
    TypographyProps,
    BorderProps,
    PositionProps {
  as?: string
}

const Span: ComponentType<SpanProps> = styled.span<SpanProps>`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${position}
  ${border}
  ${typography}
`

export default Span
