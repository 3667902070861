import isEng from 'src/utils/inEnglish.ts'

export const industries = {
  Technology: 'Technology',
  Management: 'Management',
  SalesAndMarketing: 'SalesAndMarketing',
  HR: 'HR',
  Accounting: 'Accounting',
  Commerce: 'Commerce',
  CustomerService: 'CustomerService',
  Education: 'Education',
  Finance: 'Finance',
  Other: 'other',
  // Analytics: 'Analytics',
  // Healthcare: 'Healthcare',
  // Engineering: 'Engineering',
  // Legal: 'Legal',
}

export const getIndustryTrans = (locale, industry) => {
  const arr = IndustryOptions(locale)
  const item = arr.find((element) => element.value === industry)
  return item.label
}

export default function IndustryOptions(locale) {
  return [
    { label: 'IT/Технология', value: industries.Technology },
    // { label: 'Аналитика', value: industries.Analytics },
    { label: 'Бухгалтерия', value: industries.Accounting },
    { label: 'Коммерция', value: industries.Commerce },
    { label: 'Обслуживание клиентов', value: industries.CustomerService },
    // { label: 'Здравоохранение', value: industries.Healthcare },
    { label: 'Менеджмент', value: industries.Management },
    { label: 'Образование', value: industries.Education },
    { label: 'Продажи и маркетинг', value: industries.SalesAndMarketing },
    { label: 'Рекрутинг', value: industries.HR },
    { label: 'Финансы', value: industries.Finance },
    { label: 'Другой', value: industries.Other },
  ]
}
