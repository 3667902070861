import isEng from 'src/utils/inEnglish.ts'
import TajikCities from './Tajik-cities.json'
import UzbekCities from './Uzbek-cities.json'
import KyrgyzCities from './Kyrgyz-cities.json'
import KazakhCities from './Kazakn-cities.json'

export function getCityTrans(locale, city) {
  const isEnglish = isEng(locale)
  const arr = getAllCities().map((val) => mapLabelAndValue(val, isEnglish))
  const item = arr.find((element) => element.value === city)
  return item ? item.label : 'City not found'
}

export const getAllCities = () => [
  ...TajikCities,
  ...KazakhCities,
  ...KyrgyzCities,
  ...UzbekCities,
  {
    english: 'Remote',
    russian: 'Работа удаленная',
    population: 100000000,
    coordinate: [38.547816, 68.774019],
  },
]

const mapLabelAndValue = (city, isEnglish) => ({
  label: isEnglish ? city.english : city.russian,
  value: city.english,
})

export function getCityCoordinates(city) {
  const all = getAllCities()
  const data = all.find((elem) => elem.english === city)
  return data ? data.coordinate : [38.547816, 68.774019]
}

export default function CityOptions(locale) {
  const isEnglish = isEng(locale)
  const all = getAllCities()
  const sortPopulation = (a, b) => b.population - a.population
  // const filterPopulation = (city) => city.population > 100000

  return (
    all
      // .filter(filterPopulation)
      .sort(sortPopulation)
      .map((val) => mapLabelAndValue(val, isEnglish))
  )
}
