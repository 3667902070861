import user from './user'
import offers from './offers'
import publisher from './publisher'
import { combineReducers } from 'redux'

export default combineReducers({
  user,
  offers,
  publisher,
})
