import isEng from 'src/utils/inEnglish.ts'

export const experienceOptions = (locale) => {
  const isEnglish = isEng(locale)
  return [
    {
      label: isEnglish ? 'No experience needed' : 'Опыта не требуется',
      value: '0',
    },
    { label: isEnglish ? '0-1 years' : '0-1 год', value: '0-1 years' },
    { label: isEnglish ? '1-3 years' : '1-3 год(а)', value: '1-3 years' },
    { label: isEnglish ? '3-5 years' : '3-5 года/лет', value: '3-5 years' },
    {
      label: isEnglish ? '5-10 years' : '5-10 лет',
      value: '5-10 years',
    },
    { label: isEnglish ? '10+ years' : '10+ лет', value: '10+ years' },
  ]
}

export const currencyOptions = (locale) => {
  const isEnglish = isEng(locale)
  return [
    { label: isEnglish ? 'Somoni (TJS)' : 'Сомони (TJS)', value: 'TJS' },
    { label: isEnglish ? 'Somoni (UZS)' : 'Сомони (UZB)', value: 'UZS' },
    { label: isEnglish ? 'USD' : 'Доллар', value: 'USD' },
    { label: isEnglish ? 'EUR' : 'Евро', value: 'EUR' },
  ]
}

export const contractTypes = (locale) => {
  const isEnglish = isEng(locale)
  return [
    {
      label: isEnglish ? 'Permenant' : 'Постоянный контракт',
      value: 'Permenant',
    },
    { label: isEnglish ? 'Contract' : 'Контракт', value: 'Contract' },
    { label: 'B2B', value: 'B2B' },
  ]
}

export const descriptionBoilerPlate = `
  <h2>Присоединяйтесь к нашей компании</h2>
  <br>
  <p>**Добавить описание должности**</p>
  <br>
  <p><strong>Ваши основные обязанности</strong></p>
  <ul>
      <li></li>
  </ul>
  <p>
      <br>
  </p>
  <p><strong>Вы идеально подходите на эту роль, если у вас есть</strong></p>
  <ul>
      <li></li>
  </ul>
  <p>
      <br>
  </p>
  <p><strong>Это большой плюс, если у вас есть:</strong></p>
  <ul>
      <li></li>
  </ul>
  <p>
      <br>
  </p>
  <p><strong>Мы предлагаем вам:</strong></p>
  <p>
      <br>
  </p>
  <ul>
      <li></li>
  </ul>
  <p>
      <br>
  </p>
`
