import styled from 'styled-components'

import {
  color,
  layout,
  space,
  size,
  position,
  shadow,
  border,
  typography,
  LayoutProps,
  ColorProps,
  TypographyProps,
  SpaceProps,
  FontSizeProps,
  FontStyleProps,
  BorderProps,
  SizeProps,
  ShadowProps,
  PositionProps,
} from 'styled-system'

export interface BoxProps
  extends LayoutProps,
    ColorProps,
    TypographyProps,
    SpaceProps,
    FontSizeProps,
    FontStyleProps,
    BorderProps,
    SizeProps,
    ShadowProps,
    PositionProps {
  as?: string
  shadowed?: boolean
}

const Box = styled.div<BoxProps>`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${shadow}
  ${border}
  ${position}
  ${typography}
  position: relative;
  ${({ shadowed }) =>
    shadowed &&
    'box-shadow: 0 2px 2px 0 rgba(0,0,0,0.08), 0 1px 5px 0 rgba(0,0,0,0.07);'}
`

export default Box
