import Main from 'src/components/app'
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'
import * as Sentry from '@sentry/node'

//css
import '../components/navbar/style.css'
import '../global.css'
import '../loading.css'
import 'rc-steps/assets/index.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import 'react-quill/dist/quill.snow.css'
import { initFirebase } from '../firebase'
import NextNprogress from 'nextjs-progressbar'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { wrapper } from 'src/redux/store'
import { Provider } from 'react-redux'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    enabled: true,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

function MyApp({ Component, ...rest }) {
  config.autoAddCss = false
  initFirebase()
  const { store, props } = wrapper.useWrappedStore(rest)
  return (
    <Provider store={store}>
      <Main>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
          transition={Slide}
        />

        <NextNprogress
          color="#ff4081"
          startPosition={0.3}
          stopDelayMs={0}
          height={4}
        />
        <Component {...props} />
      </Main>
    </Provider>
  )
}

export default MyApp
