// @ts-check
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  GithubAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth'
import fetcher from 'src/utils/fetcher'

/**
 * @param {string} email - Email
 * @param {string} password - Password
 */
export async function signInWithEmail(email, password) {
  try {
    return await signInWithEmailAndPassword(getAuth(), email, password)
  } catch (error) {
    throw error
  }
}

/**
 * @param {string} email - Email
 * @param {string} password - Password
 */
export async function signUpWithEmail(email, password) {
  try {
    return await createUserWithEmailAndPassword(getAuth(), email, password)
  } catch (error) {
    throw error
  }
}

export async function signInWithGmail() {
  try {
    const provider = new GoogleAuthProvider()
    return await signInWithPopup(getAuth(), provider)
  } catch (error) {
    throw error
  }
}

export async function signInWithFacebook() {
  try {
    const provider = new FacebookAuthProvider()
    return await signInWithPopup(getAuth(), provider)
  } catch (error) {
    throw error
  }
}

export async function signInWithGithub() {
  try {
    const provider = new GithubAuthProvider()
    return await signInWithPopup(getAuth(), provider)
  } catch (error) {
    throw error
  }
}

export async function sendEmailVerifictaion() {
  try {
    const currentUser = getAuth().currentUser
    if (currentUser && !currentUser.emailVerified)
      sendEmailVerification(currentUser)
  } catch (error) {
    throw error
  }
}

//Database
export async function getUserData() {
  try {
    const currentUser = getAuth().currentUser
    if (!currentUser) return
    const accessToken = await currentUser.getIdToken()
    const uid = currentUser.uid
    return await fetcher(`/user/${uid}`, {
      method: 'GET',
      headers: {
        Authorization: accessToken,
        Accept: 'application/json',
      },
    })
  } catch (error) {
    throw error
  }
}

export async function createNewUser(email) {
  try {
    const currentUser = getAuth().currentUser
    if (!currentUser) return
    const accessToken = await currentUser.getIdToken()
    const uid = currentUser.uid
    return await fetcher(`/user/${uid}`, {
      method: 'POST',
      headers: {
        Authorization: accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
  } catch (error) {
    throw error
  }
}

export async function signOutApi() {
  try {
    return await signOut(getAuth())
  } catch (error) {
    throw error
  }
}
