import styled from 'styled-components'

import {
  color,
  typography,
  layout,
  space,
  fontStyle,
  border,
  variant,
  LayoutProps,
  ColorProps,
  TypographyProps,
  SpaceProps,
  FontSizeProps,
  FontStyleProps,
  BorderProps,
} from 'styled-system'

const defaultVariant = {
  py: 2,
  px: 20,
  my: 10,
  fontSize: 14,
}

type ColorsKeys =
  | 'primary'
  | 'primary-reverse'
  | 'purplish'
  | 'purplish-reverse'
  | 'danger'
  | 'disabled'

export interface ButtonProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    LayoutProps,
    FontSizeProps,
    ColorProps,
    FontStyleProps,
    BorderProps {
  border?: string
  variant?: ColorsKeys
}

const Button = styled.button<ButtonProps>`
  ${color}
  ${space}
  ${border}
  ${layout}
  ${typography}
  ${fontStyle}
  border-radius: 25px;
  transition: 0.25s ease-in;
  border: ${({ border }) => (border ? border : 'none')};
  display: block;
  &:hover {
    filter: grayscale(10%);
    cursor: pointer;
  }
  :focus { 
    outline: none;
  }

  &:disabled {
    filter: grayscale(30%);
  }
  ${variant({
    variants: {
      primary: {
        ...defaultVariant,
        border: '1px solid #ff4081',
        bg: 'primary',
        color: 'white',
      },
      'primary-reverse': {
        ...defaultVariant,
        border: '1px solid #ff4081',
        bg: 'white',
        color: 'primary',
      },
      purplish: {
        ...defaultVariant,
        border: '1px solid #8E44AD',
        bg: 'purplish',
        color: 'white',
      },
      'purplish-reverse': {
        ...defaultVariant,
        border: '1px solid #8E44AD',
        bg: 'white',
        color: 'purplish',
      },
      danger: {
        ...defaultVariant,
        border: '1px solid #e74c3c',
        bg: '#e74c3c',
        color: 'white',
      },
      disabled: {
        ...defaultVariant,
        border: '1px solid #99a1ab',
        bg: 'white',
        color: 'rgba(0,0,0,0.7)',
      },
      raw: {
        ...defaultVariant,
        border: '1px solid #e4e8f0',

        bg: 'white',
        color: 'black',
      },
    },
  })}
`
export default Button
