import styled from 'styled-components'
import {
  color,
  layout,
  space,
  size,
  flexbox,
  border,
  position,
  typography,
  FlexProps,
  ColorProps,
  SizeProps,
  SpaceProps,
  TypographyProps,
  PositionProps,
  LayoutProps,
  BorderProps,
  AlignItemsProps,
  FlexDirectionProps,
  JustifyContentProps,
} from 'styled-system'
import { ComponentType } from 'react'

export interface FlexboxProps
  extends FlexProps,
    ColorProps,
    SizeProps,
    SpaceProps,
    TypographyProps,
    PositionProps,
    AlignItemsProps,
    AlignItemsProps,
    FlexDirectionProps,
    JustifyContentProps,
    LayoutProps,
    BorderProps {
  shadowed?: boolean
  as?: string
}

const Flex: ComponentType<FlexboxProps> = styled.div<FlexboxProps>`
  ${size}
  ${color}
  ${space}
  ${typography}
  ${position}
  ${layout}
  ${flexbox}
  ${border}
  display: flex;
  ${({ shadowed }) =>
    shadowed &&
    'box-shadow: 0 2px 2px 0 rgba(0,0,0,0.08), 0 1px 5px 0 rgba(0,0,0,0.07);'}
`
export default Flex
