import rootReducer from './reducers/rootReducer'
import { createStore, applyMiddleware } from 'redux'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'

const middleware = [thunk]

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }

    //Patch previous state of the app
    if (state.offers.allOffers.length > 0) {
      nextState.offers = state.offers
    }

    if (state.user.isAuthenticated || state.user.isAuthenticating) {
      nextState.user = state.user
    }

    return nextState
  } else {
    return rootReducer(state, action)
  }
}
// create a makeStore function
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})
const initStore = () => {
  return createStore(reducer, composeEnhancers(applyMiddleware(...middleware)))
}

export const wrapper = createWrapper(initStore)
