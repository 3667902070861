import { Span } from 'src/styled'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

const ToolTip = styled(Span)`
  position: relative;
  transition: all 0.3s ease;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  padding: 2px;
  align-items: center;
  font-size: 12px;

  // TOOLTIP BOTTOM TRIANGLE
  :before {
    content: '';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    transform: translate3d(-50%, 0%, 0);
    transition: all 0.3s ease;
    transition-delay: 0s;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #282828 transparent transparent transparent;
  }
  // TOOLTIP BUBBLE
  :after {
    text-transform: none;
    content: attr(data-tip);
    text-align: center;
    font-size: 14px;
    position: absolute;
    color: #fff;
    background: #282828;
    padding: 8px 12px;
    width: max-content;
    max-width: 200px;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    top: 0;
    border-radius: 4px;
    transform: translate3d(-50%, 0%, 0);
    transition: all 0.3s ease;
    transition-delay: 0s;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.water};
    color: ${({ theme }) => theme.colors.purplish};
    // TOOLTIP FADEIN AND TRANSLATION
    &:before {
      transform: translate3d(-50%, -100%, 0);
      opacity: 1;
    }
    &:after {
      opacity: 1;
      transform: translate3d(-50%, -100%, 0);
    }
  }
`

export default ({ tip, icon = faInfo, children }) => (
  <ToolTip data-tip={tip}>
    {children ? children : <FontAwesomeIcon icon={icon} />}
  </ToolTip>
)
