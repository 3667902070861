import { __prod__ } from 'src/constant'

//CONFIG PROD
export const API_ENDPOINT = __prod__
  ? 'https://europe-west3-bakor-bf9ff.cloudfunctions.net/api/v1'
  : 'http://localhost:5001/bakor-test/europe-west3/api/v1'

export default function fetcher(
  router: string,
  requestInit?: RequestInit,
): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(API_ENDPOINT + router, requestInit)
      .then(async (data) => resolve(await data.json()))
      .catch((error) => reject(error))
  })
}
