import Flex from './Flex'

export default function Section(props: any) {
  return (
    <Flex
      flexDirection="column"
      mt={20}
      p={[3, 10, 15, 20]}
      shadowed
      bg="white"
      borderRadius={5}
      {...props}
    />
  )
}
