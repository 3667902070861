const SET_DELETE_OFFER = 'SET_DELETE_OFFER'
const CLOSE_DELETE_OFFER = 'CLOSE_DELETE_OFFER'
const SET_OFFER_PREVIEW = 'SET_OFFER_PREVIEW'

const initState = {
  deleteModalActive: false,
  deletingOffer: {},
  previewingOffer: {},
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_DELETE_OFFER]: () => ({
      ...state,
      deleteModalActive: true,
      deletingOffer: payload,
    }),
    [CLOSE_DELETE_OFFER]: () => ({
      ...state,
      deleteModalActive: false,
      deletingOffer: {},
    }),

    [SET_OFFER_PREVIEW]: () => ({
      ...state,
      previewingOffer: payload,
    }),
  }

  return cases[type] ? cases[type]() : state
}

//Actions
export const onSetDeleteOffer = (offer) => ({
  type: SET_DELETE_OFFER,
  payload: offer,
})
export const onCloseDeleteOffer = () => ({ type: CLOSE_DELETE_OFFER })

export const setPreviewingOffer = (offer) => ({
  type: SET_OFFER_PREVIEW,
  payload: offer,
})
