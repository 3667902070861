import styled from 'styled-components'
import {
  layout,
  space,
  size,
  position,
  border,
  LayoutProps,
  SpaceProps,
  SizeProps,
  PositionProps,
  BorderProps,
} from 'styled-system'
import {default as NextImage} from 'next/image'

export interface ImageProps
  extends LayoutProps,
    SpaceProps,
    SizeProps,
    PositionProps,
    BorderProps {}

const Image = styled(NextImage)<ImageProps>`
  ${layout}
  ${size}
  ${border}
  ${space}
  ${position}
`

export default Image
