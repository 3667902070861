import { getCityCoordinates } from 'src/constant/cities';
// import { industries } from 'src/constant/industries';

const SET_OFFERS = 'SET_OFFERS';
const SET_CITY = 'SET_CITY';
const SET_CATEGORY = 'SET_CATEGORY';
const SET_MAP_VISIBILITY = 'SET_MAP_VISIBILITY';
const SET_MAP_COORDINATES = 'SET_MAP_COORDINATES';

export const INITIAL_CATEGORY_STATE = 'All';
export const INITIAL_CITY_STATE = 'Everywhere';
export const INITIAL_MAPS_VISIBILITY_STATE = false;
export const PAGINATION_SIZE = 10;

type FilteredOffers = {
  city: string;
  category: string;
};

type State = {
  allOffers: Offer[];
  filteredOffers: Offer[];
  filters: FilteredOffers;
  isMapVisible: boolean;
  mapCoordinate: [number, number];
};

const initState: State = {
  allOffers: [],
  filteredOffers: [],
  filters: {
    city: INITIAL_CITY_STATE,
    category: INITIAL_CATEGORY_STATE,
  },
  isMapVisible: INITIAL_MAPS_VISIBILITY_STATE,
  mapCoordinate: [41.287478, 69.261169],
};


export default function (state: State = initState, action: any) {
  const { type, payload } = action;

  const cases: Record<string, () => State> = {
    [SET_OFFERS]: () => ({
      ...state,
      allOffers: payload,
      filteredOffers: getFilteredOffers(
        payload,
        state.filters.category,
        state.filters.city,
      ),
    }),

    [SET_CATEGORY]: () => {
      const category = getCategory(state.filters.category, payload);
      return {
        ...state,
        filters: {
          ...state.filters,
          category,
        },
        filteredOffers: getFilteredOffers(
          state.allOffers,
          category,
          state.filters.city,
        ),
      };
    },

    [SET_CITY]: () => {
      const city = getCity(state.filters.city, payload);
      return {
        ...state,
        filters: {
          ...state.filters,
          city,
        },
        filteredOffers: getFilteredOffers(
          state.allOffers,
          state.filters.category,
          city,
        ),
        mapCoordinate: getCityCoordinates(city),
      };
    },

    [SET_MAP_VISIBILITY]: () => {
      const isMapVisible = determineMapsVisibility(state.isMapVisible, payload);
      return {
        ...state,
        isMapVisible: isMapVisible,
      };
    },

    [SET_MAP_COORDINATES]: () => ({
      ...state,
      mapCoordinate: payload,
    }),
  };

  return cases[type] ? cases[type]() : state;
}

// Actions
export const setMapCoordinates = (coordinates: [number, number]) => ({
  type: SET_MAP_COORDINATES,
  payload: coordinates,
});

export const setOffers = (offers: any[]) => ({ type: SET_OFFERS, payload: offers }); // Replace 'any[]' with the actual type for job offers

export const setOffersCity = (city: string) => ({
  type: SET_CITY,
  payload: city,
});

export const setOffersCategory = (category: string) => ({
  type: SET_CATEGORY,
  payload: category,
});

export const setMapVisibility = (isMapVisible: boolean) => ({
  type: SET_MAP_VISIBILITY,
  payload: isMapVisible,
});

// Helper functions
export const getFilteredOffers = (offers: Offer[], category: string, city: string) => {
  return offers
        .filter((offer) => filterCity(offer, city))
        .filter((offer) => filterCategory(offer, category))
};

export const filterCity = (offer: Offer, city: string) =>
  offer.city === city || city === INITIAL_CITY_STATE;

export const filterCategory = (offer: Offer, category: string) =>
  offer.category === category || category === INITIAL_CATEGORY_STATE;

// Set 'All' if the same category/city is selected
export const getCity = (oldCity: string, newCity: string) =>
  oldCity === newCity ? INITIAL_CITY_STATE : newCity;

export const getCategory = (oldCategory: string, newCategory: string) =>
  oldCategory === newCategory ? INITIAL_CATEGORY_STATE : newCategory;

export const determineMapsVisibility = (oldMapsVisibility: boolean, newMapsVisibility: boolean) =>
  oldMapsVisibility === newMapsVisibility
    ? INITIAL_MAPS_VISIBILITY_STATE
    : newMapsVisibility;

