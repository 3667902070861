import styled from 'styled-components'
import { ComponentType } from 'react'
import {
  color,
  layout,
  space,
  size,
  typography,
  ColorProps,
  LayoutProps,
  SpaceProps,
  SizeProps,
  TypographyProps,
} from 'styled-system'

export interface TextProps
  extends ColorProps,
    LayoutProps,
    SpaceProps,
    SizeProps,
    TypographyProps {
  as?: string
}

const Text: ComponentType<TextProps> = styled.p<TextProps>`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export default Text
