import { Box, Flex } from 'src/styled'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import readFile from 'src/utils/readFile'
import defaultImage from 'src/assets/img/default-company-logo.png'
import Image from 'next/image'

const Container = styled(Box)`
  margin: auto;
`

const AvatarUpload = styled.div`
  position: relative;
  max-width: 200px;
  margin: auto;
`

const AvatarEdit = styled.div`
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
  input {
    display: none;
    + label {
      width: 34px;
      height: 34px;
      margin-bottom: 0;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid transparent;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      font-weight: normal;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: #f1f1f1;
        border-color: #d6d6d6;
      }
    }
  }
`

const EditIcon = styled(FontAwesomeIcon)`
  color: #757575;
`

const AvatarPreview = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #f0f0f0;
  justify-content: center;

  img {
    width: 65%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`

export default function UploadImage({ uploadImage, image = defaultImage }) {
  function onUploadImage({ target: input }) {
    const files = input.files
    if (files && files[0]) {
      readFile(files[0]).then((base64) => uploadImage(base64))
    }
  }

  return (
    <Container>
      <AvatarUpload>
        <AvatarEdit>
          <input
            type="file"
            id="imageUpload"
            accept=".png, .jpg, .jpeg"
            onChange={onUploadImage}
          />

          <Flex
            as="label"
            justifyContent="center"
            alignItems="center"
            htmlFor="imageUpload"
          >
            <EditIcon icon={faEdit} />
          </Flex>
        </AvatarEdit>

        <AvatarPreview>
          <Image width={65} height={100} id="imagePreview" src={image} />
        </AvatarPreview>
      </AvatarUpload>
    </Container>
  )
}
