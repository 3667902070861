import { FC, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Grid, Image, Box } from 'src/styled'
import { devices } from 'src/theme/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import readFile from 'src/utils/readFile'

export interface Props {
  uploadable?: boolean
  images?: string[]
  onUpload?: (srcArray: (string | ArrayBuffer)[]) => void
  onDelete?: (index: number) => void
  maxUploads?: number
}

const ImageGrid = styled(Grid)`
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  ${devices.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${devices.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Delete = styled(Box)`
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  svg {
    margin: auto;
  }
  cursor: pointer;
  color: white;
  font-size: 12px;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
`

const Upload = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.1s ease-in;
  display: inline-block;
  height: 250px;
  input {
    display: none;
    + label {
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    margin: auto;
    display: inline-block;
  }
  &:hover {
    padding-bottom: 10px;
  }
`

const ImageGallery: FC<Props> = ({
  onDelete,
  onUpload,
  images,
  uploadable,
  maxUploads = 10,
}) => {
  function onUploadImages(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files
    if (!files || files.length === 0) return

    const allFilePromises: Promise<string | ArrayBuffer>[] = []

    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      allFilePromises.push(readFile(files[i]))
    }

    Promise.all(allFilePromises).then((dataURLs) => {
      onUpload && onUpload(dataURLs)
    })

    //Empty the value for uploading same image
    e.target.value = ''
  }
  return (
    <ImageGrid>
      {images &&
        images.map((image, index) => (
          <Box shadowed key={index}>
            <StyledImage width={100} height={100} src={image} />
            {onDelete && (
              <Delete onClick={() => onDelete(index)}>
                <FontAwesomeIcon icon={faTimes} />
              </Delete>
            )}
          </Box>
        ))}

      {uploadable && (images ? maxUploads > images.length : true) && (
        <Upload>
          <input
            multiple
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={onUploadImages}
            id="uploadImage"
          />

          <label htmlFor="uploadImage">
            <FontAwesomeIcon size={'5x'} icon={faArrowCircleUp} />
          </label>
        </Upload>
      )}
    </ImageGrid>
  )
}

export default ImageGallery
