import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { devices } from 'src/theme/device'
import { Flex, Box } from 'src/styled'
import theme from 'src/theme'
import Image from 'next/image'

export const StyledBox = styled(Flex)`
  ${devices.laptop} {
    display: none;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-items: center;
  a {
    margin: auto;
    padding: 10px;
    text-decoration: none;
    color: ${theme.colors.secondary};
    font-size: 15px;
    &:hover {
      cursor: pointer;
      color: ${theme.colors.unfocuesPrimary};
    }
    &.is-active {
      color: ${theme.colors.primary};
    }

    &:not(&:first-child) {
      ${devices.laptop} {
        display: none;
      }
    }
  }
  :first-child {
    padding-left: 0;
  }
`

export const Burger = styled(FontAwesomeIcon)`
  &:hover {
    color: ${theme.colors.primary};
    cursor: pointer;
  }
`

export const BurgerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  color: ${theme.colors.secondary};

  display: none;
  ${devices.laptop} {
    display: inherit;
  }
`

export const SideNavOverlay = styled.div`
  ${({ isActive }) =>
    isActive &&
    `
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
  `}
  display: ${({ isActive }) => (isActive ? 'inital' : 'none')};
`

export const NavigationContainer = styled(Flex)`
  position: fixed;
  height: 75px;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
  background: white;
  border-bottom: 1px solid ${theme.colors.lightBlue};
`

export const RightNav = styled(Flex)`
  display: flex;
  align-items: center;
`

const slideUp = keyframes`
  0% { transform: translateY(100%); }
  40%, 60% { transform: translateY(50%); }
  100% { transform: translateY(0); }
`

const slideLeft = keyframes`
  0% { 
    opacity: 1;
    transform: translateX(100%); 
  }
  100% { 
    opacity: 1;
    transform: translateX(0); 
  }

`

export const LogoImage = styled(Image)`
  height: 45px;
  width: 45px;
  transition: 0.1s ease-in;
`

export const LogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  &:hover {
    ${LogoImage} {
      filter: drop-shadow(-5px 5px 0px #ccc);
    }
  }
  cursor: pointer;
`

export const HeaderWrapper = styled(Box)`
  overflow: hidden;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  color: black;

  #thin {
    font-weight: 400;
    display: inline;
    font-size: 30px;
    animation: ${slideLeft} 0.5s linear;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
    will-change: transform, opacity;
  }

  #thick {
    animation: ${slideUp} 0.5s linear;
    font-weight: 900;
    font-size: 30px;
    background: white;
    z-index: 2;
    animation-delay: 1.5s;
    transform: translateY(100%);
    will-change: transform;
    animation-fill-mode: forwards;
  }
`
