import { FC, ChangeEvent } from 'react'
import styled from 'styled-components'
import readFile from 'src/utils/readFile'

import theme from 'src/theme'
import { Box } from 'src/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const Container = styled(Box)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: black;
  transition: 0.2s ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 50px;
  overflow: hidden;
  label {
    display: flex; 
    align-items: center;
    transition: 0.2s ease-out;
    color: gray;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  svg {
    margin-right: 5px;
  }

  &:hover {
    border-color: ${theme.colors.primary};
    label {
      color: ${theme.colors.primary};
    }
  }
`

export interface UploadPDFProps {
  label: string
  onUpload: (fileName: string, base64: string | ArrayBuffer) => void
}

const UploadPDF: FC<UploadPDFProps> = ({ label, onUpload }) => {
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files
    if (!files || files.length === 0) return

    const file = files[0]
    readFile(file).then((value) => onUpload(file.name, value))
  }

  return (
    <Container>
      <input
        type="file"
        accept="application/pdf"
        onChange={onChange}
        id="uploadPDF"
        hidden
      />

      <label htmlFor="uploadPDF">
        <FontAwesomeIcon size={'lg'} icon={faFilePdf} /> {label}
      </label>
    </Container>
  )
}

export default UploadPDF
