// @ts-check
import { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faBullhorn,
  faSignOutAlt,
  faUser,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons'

import Slider from './Slider'
import { Button } from 'src/styled'
import {
  BurgerWrapper,
  NavigationContainer,
  ListWrapper,
  SideNavOverlay,
  Burger,
  RightNav,
  StyledBox,
  LogoWrapper,
  HeaderWrapper,
  LogoImage,
} from './styled'

import logo from 'src/assets/img/logo-trans.png'
import { useDispatch, useSelector } from 'react-redux'
import userTypes from 'src/constant/userTypes'
import { signOut } from 'src/redux/reducers/user'

function Navbar() {
  const [isActive, setIsActive] = useState(false)
  const { isAuthenticated, userData } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const hasPublishingAccess =
    userData &&
    (userTypes.PUBLISHER === userData.userType ||
      userTypes.COMPANY === userData.userType)

  const router = useRouter()
  function toggleSlider() {
    document.body.classList.toggle('body-disabled')
    const slider = document.getElementById('slider')
    slider.classList.toggle('active')
    setIsActive(!isActive)
  }

  function onSignOut() {
    dispatch(signOut())
  }
  function isLinkActive(link) {
    return link === router.pathname ? 'is-active' : ''
  }

  return (
    <NavigationContainer>
      <ListWrapper>
        <Link href="/">
          <LogoWrapper>
            <LogoImage priority src={logo} />

            <HeaderWrapper>
              <p id="thin">Ба</p>
              <div id="thick">кор</div>
            </HeaderWrapper>
          </LogoWrapper>
        </Link>

        <Link href="/" className={isLinkActive('/')}>
          {'Вакансии'}
        </Link>

        <Link className={isLinkActive('/company/all')} href="/company/all">
          {'Компании'}
        </Link>

        <Link className={isLinkActive('/about')} href="/about">
          {'О нас'}
        </Link>

        <Link className={isLinkActive('/contact')} href="/contact">
          {'Связаться с нами'}
        </Link>
      </ListWrapper>

      <RightNav>
        <StyledBox>
          <Link style={{ marginRight: 10 }} href="/offers/new">
            <Button variant={'primary'}>
              {'Разместить вакансию'} <FontAwesomeIcon icon={faBullhorn} />
            </Button>
          </Link>

          {!isAuthenticated && (
            <Link style={{ marginRight: 10 }} href="/auth/sign-in">
              <Button variant={'primary'}>
                {'Войти'} <FontAwesomeIcon icon={faUser} />
              </Button>
            </Link>
          )}
          {isAuthenticated && hasPublishingAccess && (
            <Link style={{ marginRight: 10 }} href="/admin/offers">
              <Button variant={'primary'}>
                {'Панель админа'} <FontAwesomeIcon icon={faUserEdit} />
              </Button>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/">
              <Button onClick={onSignOut} variant={'primary-reverse'}>
                {'Выйти'} <FontAwesomeIcon icon={faSignOutAlt} />
              </Button>
            </Link>
          )}

          {/* <Link href="/contact">
            <Button variant={'primary'}>Возникли проблемы?</Button>
          </Link> */}
        </StyledBox>

        <BurgerWrapper>
          <Burger onClick={toggleSlider} icon={faBars} size={'lg'} />
        </BurgerWrapper>
      </RightNav>

      <SideNavOverlay id="overlay" isActive={isActive} onClick={toggleSlider} />

      <Slider toggleSlider={toggleSlider} />
    </NavigationContainer>
  )
}

export default Navbar
