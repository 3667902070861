import { Box, Text, Grid, Flex, Header, Span } from 'src/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faFile,
  faBuilding,
  faGlasses,
  faClock,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { devices } from 'src/theme/device'
import { getDayOfPublication } from 'src/utils/getTimesPassed'
import { getCityTrans } from 'src/constant/cities'

const Icon = styled(Box)`
  position: absolute;
  top: -20px;
  height: 35px;
  width: 35px;
  padding: 7px;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: white;
`

const BrandImage = styled(Flex)`
  background: ${({ imgURL }) => `url(${imgURL})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  ${devices.laptop} {
    width: 50px;
    height: 50px;
  }
`

const DetailCard = styled(Box)`
  position: relative;
  background: white;
  padding: 18px 20px;
  border-radius: 5px;
  ${devices.laptop} {
    border-radius: 0;
  }
`

const DetailContainer = styled(Grid)`
  position: relative;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(5, 1fr);

  ${devices.laptop} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    padding: 0;
    ${DetailCard}:last-child {
      grid-column: span 2;
    }
  }
`

const DescriptionContainer = styled(Flex)`
  flex-direction: column;
  text-align: center;
  height: fit-content;
  > span {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .value {
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 14px;
  }

  .data {
    color: ${({ theme }) => theme.colors.secondary};
    padding-top: 3px;
    font-size: 12px;
    font-weight: 200;
  }
`

const InfoText = styled(Text)`
  font-size: 16px;
  padding: 10px 0;
  font-weight: 100;
  color: white;
`

const InfoContaienr = styled(Flex)`
  flex: 1;
  padding: 0 20px;
  flex-direction: column;
  justify-content: center;
`

const ImageContainer = styled(Box)`
  padding: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
`

const BoxWrapper = styled(Box)`
  background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
  margin-bottom: 30px;
  height: 250px;
  border-radius: 5px;
  oveflow: hidden;

  ${devices.laptop} {
    height: fit-content;
    margin-bottom: 0px;
  }
`

const DataWrapper = styled(Flex)`
  padding: 20px;
  ${devices.laptop} {
    padding: 10px;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

function OfferPreview({ offer }) {
  const {
    companyLogo,
    minSalary,
    maxSalary,
    currency,
    title,
    city,
    companyName,
    companySize,
    contractType,
    createdAt,
    experience,
    children,
    companyAddress,
  } = offer

  const offerPublicationDay = getDayOfPublication(createdAt, 'ru')

  return (
    <BoxWrapper width={1} shadowed>
      <DataWrapper>
        <div
          style={{
            border: '9px solid rgba(255, 255, 255, .1)',
            borderRadius: '50%',
            height: 'fit-content',
            width: 'fit-content',
          }}
        >
          <div
            style={{
              border: '9px solid rgba(255, 255, 255, .3)',
              borderRadius: '50%',
            }}
          >
            <ImageContainer shadowed>
              <BrandImage imgURL={companyLogo} />
            </ImageContainer>
          </div>
        </div>

        <InfoContaienr>
          {!!minSalary && (
            <InfoText>
              {minSalary === maxSalary
                ? minSalary
                : `${minSalary}-${maxSalary} `}
              {currency}
            </InfoText>
          )}
          <Header fontSize={20} color="white">
            {title}
          </Header>
          <InfoText>
            {companyAddress}, {getCityTrans('ru', city)}
          </InfoText>
        </InfoContaienr>
        {children}
      </DataWrapper>

      <DetailContainer>
        {/* Comany name */}
        <DetailCard shadowed>
          <Icon shadowed>
            <FontAwesomeIcon icon={faBuilding} style={{ color: '#ff5252' }} />
          </Icon>

          <DescriptionContainer>
            <Span className="value"> {companyName} </Span>
            <Span className="data">{'Компания'}</Span>
          </DescriptionContainer>
        </DetailCard>

        {/* Company size */}
        <DetailCard shadowed>
          <Icon shadowed>
            <FontAwesomeIcon icon={faUsers} style={{ color: '#fb8c00' }} />
          </Icon>
          <DescriptionContainer>
            <Span className="value"> {companySize} </Span>
            <Span className="data">{'Численность работников'}</Span>
          </DescriptionContainer>
        </DetailCard>

        {/* Contract type */}
        <DetailCard shadowed>
          <Icon shadowed>
            <FontAwesomeIcon icon={faFile} style={{ color: '#ab47bc' }} />
          </Icon>

          <DescriptionContainer>
            <Span className="value"> {contractType} </Span>
            <Span className="data">{'Контракт'}</Span>
          </DescriptionContainer>
        </DetailCard>

        <DetailCard shadowed>
          <Icon shadowed>
            <FontAwesomeIcon icon={faGlasses} style={{ color: '#66bb6a' }} />
          </Icon>

          <DescriptionContainer>
            <Span className="value"> {experience} </Span>
            <Span className="data">{'Опыт работы'}</Span>
          </DescriptionContainer>
        </DetailCard>

        {/* Upload Date */}
        <DetailCard shadowed>
          <Icon shadowed>
            <FontAwesomeIcon icon={faClock} style={{ color: '#448aff' }} />
          </Icon>

          <DescriptionContainer>
            <Span className="value"> {offerPublicationDay} </Span>
            <Span className="data">{'Добавлено'}</Span>
          </DescriptionContainer>
        </DetailCard>
      </DetailContainer>
    </BoxWrapper>
  )
}

export default OfferPreview
