import { DefaultTheme } from 'styled-components'

const colors = {
  blue: '#4256ef',
  water: '#f3f6f8',
  greeny: '#1ec66c',
  primary: '#ff4081',
  purplish: '#8E44AD',
  lightBlue: '#d9ddfc',
  secondary: '#99a1ab',
  lightGray: '#f3f6f8',
  darkGray: 'rgba(0,0,0,0.7)',
  shadowedPrimary: '#d02b63',
  lightPrimary: '#f54782',
  unfocuesPrimary: '#f47da6',
  washedOutBlack: 'rgba(0,0,0,0.2)',
  danger: '#e74c3c',
  textGrey: 'rgb(153, 161, 171)',
  littleBlue: "#ddeefc" 
}

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]
const border = ['2.5px', '5px', '7.5px', '10px', '12.5px']
const breakpoints = ['40em', '54em', '64em', '80em']

const theme: DefaultTheme = {
  space,
  colors,
  border,
  breakpoints,
}
export default theme
