import { FC, ComponentType, useState } from 'react'
import { Loading } from 'src/components/common'

export default function loadWithTimeout<P extends object>(
  Component: ComponentType<P>,
  timeout = 2000,
  LoadingIcon: FC,
) {
  function Page(props: any) {
    const [isLoading, setIsLoading] = useState(true)

    setTimeout(() => {
      setIsLoading(false)
    }, timeout)

    return isLoading ? (
      LoadingIcon ? (
        <LoadingIcon />
      ) : (
        <Loading color="gray" />
      )
    ) : (
      <Component {...props} />
    )
  }

  return Page
}
