import styled from 'styled-components'
import { Box, Grid, Flex, Text } from 'src/styled'
import Router from 'next/router'
import { setMapVisibility } from 'src/redux/reducers/offers'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { LazyImage } from 'src/components/common'

const PopoverWrapper = styled(Box)`
  position: absolute;
  min-height: 70px;
  bottom: 17px;
  width: 220px;
  left: -80px;
  background-color: white;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 400px;
  overflow: auto;
`
const Indicator = styled(Box)`
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: '';
  height: 15px;
  left: 100px;
  position: absolute;
  bottom: -15px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
`

const Row = styled(Flex)`
  width: 100%;
  flex: 1;
  padding: 0 10px;
  &:hover {
    width: 100%;
    background: rgb(238, 238, 238);
  }
  > #row-image {
    align-items: center;
    justify-content: center;
    padding: 4px 0;
  }
`
const Title = styled(Text)`
  text-align: left;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Content = styled(Text)`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Popover = ({ companyOffers }) => {
  const dispatch = useDispatch()

  const displayOffer = useCallback((isMapVisible) =>
    dispatch(setMapVisibility(isMapVisible)),
  )

  const handleRedirect = (id) => {
    Router.push(`/?id=${id}`)
    displayOffer()
  }

  return (
    <PopoverWrapper>
      <Grid my={10} gridAutoFlow={'row'} gridGap={1} width={1}>
        {companyOffers &&
          companyOffers.map((offer) => {
            const {
              id,
              title,
              companyName,
              companyLogo,
              minSalary,
              maxSalary,
              currency,
            } = offer

            return (
              <Row key={id} onClick={() => handleRedirect(id)}>
                <Flex id="row-image" mr={10}>
                  <LazyImage
                    width={50}
                    height={'auto'}
                    data-src={companyLogo}
                    alt={companyName}
                  />
                </Flex>

                <Flex flex={1}>
                  <Grid gridAutoFlow="row" gridGap={1}>
                    <Title fontSize={12}>{title}</Title>
                    {!!minSalary && (
                      <Content color={'greeny'} fontSize={11}>
                        {minSalary === maxSalary
                          ? minSalary
                          : `${minSalary}-${maxSalary} `}
                        {currency}
                      </Content>
                    )}
                    <Content color="secondary" fontSize={12}>
                      {companyName}
                    </Content>
                  </Grid>
                </Flex>
              </Row>
            )
          })}
      </Grid>
      <Indicator />
    </PopoverWrapper>
  )
}

export default Popover
