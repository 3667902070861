import { Flex } from 'src/styled'
import GoogleMapReact from 'google-map-react'
import loadWithTimeout from 'src/utils/loadWithTimeout'
import MapLoader from './MapContainer/MapLoading'

const GMAPS_API_KEY =
  process.env.NODE_ENV === 'development'
    ? ''
    : 'AIzaSyBvOx1C-1391jXAOK18AkTShA9Lxh0dEMY'

function GoogleMaps({ zoom = 12, children, onClick, coordinates }) {
  const coord = {
    lat: coordinates[0],
    lng: coordinates[1],
  }

  return (
    <Flex flex={1}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAPS_API_KEY }}
        defaultZoom={zoom}
        defaultCenter={{ lat: 38.5598, lng: 68.787 }}
        center={coord}
        hoverDistance={20}
        {...(onClick && { onClick: onClick })}
      >
        {children}
      </GoogleMapReact>
    </Flex>
  )
}

export default loadWithTimeout(GoogleMaps, 3000, MapLoader)
