import React from 'react'
import styled from 'styled-components'

import Tooltip from './Tooltip'
import { Text, Span } from 'src/styled'
import { devices } from 'src/theme/device'
import generateID from 'src/utils/generateId'

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0 10px 0;
`

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.2s ease-out;
  transform-origin: left top;
  pointer-events: none;
  color: gray;

  ${devices.tablet} {
    font-size: 14px;
    padding: 0 0.1rem;
  }
`

const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  transition: 0.2s ease-out;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus + label  {
    color: ${({ theme }) => theme.colors.primary};
    top: 0;
    transform: translateY(-50%) scale(0.75);
  }
  &:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.75);
  }
`

const ErrorLabel = styled(Text)`
  font-size: 11px;
  position: absolute;
  width: 100%;
  bottom: -15px;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.danger};
`

const TipWrapper = styled(Span)`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
`

interface Props {
  label: string
  value: string | number
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  error?: string
  tip?: string
  type?: string
  name?: string
  disabled?: boolean
}

export default function InputField({
  label,
  value,
  onChange,
  error,
  tip,
  type,
  name,
  disabled,
  ...rest
}: Props) {
  const id: string = generateID()

  return (
    <Container>
      <Input
        id={id}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        placeholder=" " //Do NOT remove this (it makes the label animation working)
        disabled={disabled}
        {...rest}
      />
      <Label>{label}</Label>

      {tip && (
        <TipWrapper>
          <Tooltip children={undefined} tip={tip} />
        </TipWrapper>
      )}

      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Container>
  )
}
