import styled from 'styled-components'

import { Text } from 'src/styled'

const StyledText = styled(Text)`
  white-space: nowrap;
  margin-left: 5px;
`

const Checkbox = ({ label, checked, onChange, ...rest }) => {
  return (
    //the css classNames comes from global.css
    <label className="container">
      <StyledText {...rest}>{label}</StyledText>

      <input
        type="checkbox"
        checked={checked}
        onClick={(e) => onChange(!checked)}
      />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox

