import { FC, useState } from 'react'
import styled from 'styled-components'

import { Grid, Span, Flex } from 'src/styled'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from './Input'
import Rating from './Rating'

interface AddBoxProps {
  onClick: () => void
}

const AddBox = styled(Span)<AddBoxProps>`
  border: 1px solid
    ${({ theme }: { theme: any }) => theme.colors.washedOutBlack};
  border-radius: 5px;
  color: ${({ theme }: { theme: any }) => theme.colors.darkGray};
  padding: 5px 8px 5px 8px;
  &:hover {
    color: ${({ theme }: { theme: any }) => theme.colors.primary};
    border: 1px solid ${({ theme }: { theme: any }) => theme.colors.primary};
  }

  display: inline-table;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
    color: ${({ theme }: { theme: any }) => theme.colors.lightPrimary};
  }
  font-size: 12px;
`

const InputContainer = styled(Grid)`
  grid-auto-flow: column;
  grid-gap: 10px;
  border: 1px solid
    ${({ theme }: { theme: any }) => theme.colors.washedOutBlack};
  border-radius: 5px;
  max-width: fit-content;
  padding: 5px;
`

const Container = styled(Flex)`
  flex-wrap: wrap;
  margin-top: 10px;
`

interface Rating {
  level: number
  name: string
}

interface Props {
  onDelete: (index: number) => void
  max: number
  onAddOption: (rating: Rating) => void
  ratingList: Rating[]
  extendable: boolean
  onChangeRating: (rating: number, index: number) => void
  onDeleteRating: (index: number) => void
}

const RatingSelection: FC<Props> = ({
  ratingList,
  onAddOption,
  max,
  extendable,
  onChangeRating,
  onDeleteRating,
}) => {
  const [isExtending, setIsExtending] = useState(false)
  const [additionalItem, setAdditionalItem] = useState('')

  function onAppendItem() {
    onAddOption({ level: 1, name: additionalItem })
    setAdditionalItem('')
    setIsExtending(false)
  }

  return (
    <Container>
      {ratingList &&
        ratingList.length > 0 &&
        ratingList.map((rating, index) => (
          <Rating
            key={index}
            name={rating.name}
            rating={rating.level}
            {...(onChangeRating && {
              onRate: (rating: number) => onChangeRating(rating, index),
            })}
            {...(onDeleteRating && { onDelete: () => onDeleteRating(index) })}
          />
        ))}

      {extendable && !isExtending && ratingList.length < max && (
        <AddBox onClick={() => setIsExtending(true)}>
          <FontAwesomeIcon icon={faPlus} size={'1x'} />
        </AddBox>
      )}

      {isExtending && (
        <InputContainer>
          <FontAwesomeIcon
            color={'red'}
            icon={faTimes}
            onClick={() => setIsExtending(false)}
          />

          <Input
            type={'text'}
            label={'Навык'}
            value={additionalItem}
            onChange={(e: any) => setAdditionalItem(e.currentTarget.value)}
            tip=""
            error=""
          />

          <FontAwesomeIcon
            size={'1x'}
            icon={faPlus}
            color={'#1ec66c'}
            onClick={onAppendItem}
          />
        </InputContainer>
      )}
    </Container>
  )
}

export default RatingSelection
