import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Box } from 'src/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import theme from 'src/theme'
import locales from 'src/constant/locales'

const Container = styled(Box)`
  height: 75px;
  width: 120px;
  display: inline-block;
  margin-top: 10px;
  padding-right: 10px;

  > .header {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
    color: ${theme.colors.darkGrayk};
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  > .description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: light;
    font-size: 14px;
    color: ${theme.colors.secondary};
  }
`

const Circle = styled(Box)`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.secondary};
  ${({ onClick }) => onClick && `cursor: pointer;`}
`

const Delete = styled(Box)`
  background: ${theme.colors.danger};
  color: white;
  position: absolute;
  top: -25px;
  right: 25px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 12px;
  padding: 0;
  text-align: center;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
`

const getDescription = (locale) => {
  const english = {
    1: 'Nice to have',
    2: 'Junior',
    3: 'Regular',
    4: 'Advanced',
    5: 'Expert',
  }

  const russian = {
    1: 'Приветствуется',
    2: 'Юниор',
    3: 'Средний уровень',
    4: 'Высший уровень',
    5: 'Эксперт',
  }

  return locale === locales.en ? english : russian
}

const SkillHeader = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`

function Rating({ name, rating, maxRating = 5, onRate, onDelete }) {
  const description = getDescription('ru')[rating]

  const ratingItems = () => {
    let items = []
    for (let i = 0; i < maxRating; i++) {
      items.push(
        <Circle
          active={i < rating}
          {...(onRate && { onClick: () => onRate(i + 1) })}
        />,
      )
    }
    return items
  }

  return (
    <Container>
      {onRate && (
        <Delete onClick={onDelete}>
          <FontAwesomeIcon icon={faTimes} />
        </Delete>
      )}
      <Flex mb={10}> {ratingItems()} </Flex>
      <SkillHeader> {name} </SkillHeader>
      <Text className="description"> {description} </Text>
    </Container>
  )
}

export default Rating
