import React from 'react'

export default function MapLoading() {
  return (
    <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  )
}
