import { __prod__ } from '../constant'

const productionConfig = {
  apiKey: 'AIzaSyAPQ9Hpt6dWvjfn-jE7JWk16wp4dm1Hl7Y',
  authDomain: 'bakor-bf9ff.firebaseapp.com',
  databaseURL: 'https://bakor-bf9ff.firebaseio.com',
  projectId: 'bakor-bf9ff',
  storageBucket: 'bakor-bf9ff.appspot.com',
  messagingSenderId: '897938381607',
  appId: '1:897938381607:web:0ef2c78dfefeeb902b6702',
  measurementId: 'G-J2E0M883T3',
}

const testConfig = {
  apiKey: 'AIzaSyC1kWUXj6Id4kHs4XZVCrOyM41TjPztDgc',
  authDomain: 'bakor-test.firebaseapp.com',
  databaseURL: 'https://bakor-test.firebaseio.com',
  projectId: 'bakor-test',
  storageBucket: 'bakor-test.appspot.com',
  messagingSenderId: '231909424522',
  appId: '1:231909424522:web:720c95f7b6a48d7fdb1c73',
  measurementId: 'G-3FTJPNPQKR',
}

//CONFIG PROD
export default __prod__ ? productionConfig : testConfig
