import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

const BrandIcon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  transition: 0.15s ease-in-out;
  margin-left: 10px;
`

export default function SocialMediaButton({ link, color, icon }) {
  function fixLink(link) {
    // Check if the link starts with "http://www.", "https://www.", or "www."
    if (
      !/^https:\/\/www\..*/.test(link) &&
      !/^http:\/\/www\..*/.test(link) &&
      !/^www\..*/.test(link)
    ) {
      // If it doesn't start with any of the expected prefixes, prepend "https://www."
      return 'https://www.' + link
    }
    // If it starts with the expected prefixes, return the link as is
    return link
  }
  return (
    <Link href={fixLink(link)} target="_blank" rel="noopener noreferrer">
      <BrandIcon icon={icon} color={color} size={'2x'} />
    </Link>
  )
}
