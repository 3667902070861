// @ts-check
import {
  getUserData,
  signInWithEmail,
  signUpWithEmail,
  createNewUser,
  sendEmailVerifictaion,
  signOutApi,
} from 'src/modules/auth/api'

const SIGN_IN = 'auth/SIGN_IN'
const SIGN_OUT = 'auth/SIGN_OUT'
const SET_AUTH_LOADING = 'auth/SET_AUTH_LOADING'
const SIGN_IN_ERROR = 'auth/SIGN_IN_ERROR'
const SIGN_UP_ERROR = 'auth/SIGN_UP_ERROR'
const SET_USER_CITY = 'auth/SET_USER_CITY'

const initState = {
  userData: {},
  isAuthenticated: false,
  isAuthenticating: false,
  signInError: '',
  signUpError: '',
  city: null,
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SIGN_OUT]: () => ({
      ...state,
      userData: {},
      isAuthenticated: false,
    }),

    [SIGN_IN]: () => ({
      ...state,
      userData: payload,
      isAuthenticated: true,
      signInError: '',
    }),

    [SIGN_IN_ERROR]: () => ({
      ...state,
      signInError: payload,
    }),

    [SET_AUTH_LOADING]: () => ({
      ...state,
      isAuthenticating: payload,
    }),

    [SIGN_UP_ERROR]: () => ({
      ...state,
      signUpError: payload,
    }),

    [SET_USER_CITY]: () => ({
      ...state,
      city: payload,
    }),
  }

  return cases[type] ? cases[type]() : state
}

//Actions
export const isAuthenticating = (boolean) => ({
  type: SET_AUTH_LOADING,
  payload: boolean,
})

export const setUserCity = (city) => ({
  type: SET_USER_CITY,
  payload: city,
})

const signInError = (errorMessage) => ({
  type: SIGN_IN_ERROR,
  payload: errorMessage,
})

const signUpError = (errorMessage) => ({
  type: SIGN_UP_ERROR,
  payload: errorMessage,
})

export const signInAction = (userData) => ({ type: SIGN_IN, payload: userData })

export function signIn(email, password) {
  return async (dispatch, getState) => {
    const isAuthenticated = getState().user.isAuthenticated
    if (isAuthenticated) {
      return
    }
    dispatch(isAuthenticating(true))
    try {
      await signInWithEmail(email, password)
      const { user } = await getUserData()
      dispatch(signInAction(user))
    } catch (error) {
      dispatch(
        signInError(
          'Ошибка при входе в систему. Введите правильный адрес электронной почты и пароль.',
        ),
      )
    }

    dispatch(isAuthenticating(false))
  }
}

export function signUp(email, password) {
  return async (dispatch) => {
    dispatch(isAuthenticating(true))

    try {
      await signUpWithEmail(email, password)
      const { user: userData } = await createNewUser(email)
      dispatch(signInAction(userData))
      await sendEmailVerifictaion()
    } catch (error) {
      console.error(error)
      dispatch(signUpError('Ошибка при регистрации, попробуйте еще раз.'))
    }

    dispatch(isAuthenticating(false))
  }
}

export function fetchUserData(forced = false) {
  return async (dispatch, getState) => {
    const { isAuthenticated } = getState().user
    if (isAuthenticated && !forced) {
      return
    }
    try {
      const { user } = await getUserData()
      dispatch(signInAction(user))
    } catch (error) {
      console.error(error)
    }
  }
}

export function signOut() {
  return async (dispatch) => {
    try {
      const data = await signOutApi()
      dispatch({ type: SIGN_OUT })
    } catch (error) {
      console.error(error)
    }
  }
}
