export default function readFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      let dataURL = reader.result
      if (!dataURL) {
        reject('Error')
        return
      }
      resolve(dataURL)
    }

    reader.readAsDataURL(file)
  })
}
