import { getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import 'firebase/storage'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

import config from './config'
import { __prod__ } from 'src/constant'

export function initFirebase() {
  if (getApps().length > 0) return
  if (!__prod__) {
    initializeApp(config)
    connectAuthEmulator(getAuth(), 'http://127.0.0.1:9099')
    connectStorageEmulator(getStorage(), '127.0.0.1', 9199)
    return
  }

  initializeApp(config)
  getAuth().useDeviceLanguage()
}
