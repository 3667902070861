import styled from 'styled-components'
import { ComponentType } from 'react'
import {
  color,
  layout,
  space,
  size,
  grid,
  position,
  LayoutProps,
  GridProps,
  ColorProps,
  SpaceProps,
  SizeProps,
  PositionProps,
} from 'styled-system'

export interface GridboxProps
  extends LayoutProps,
    ColorProps,
    SpaceProps,
    SizeProps,
    PositionProps,
    GridProps {
  as?: string
}

const Grid: ComponentType<GridboxProps> = styled.div<GridboxProps>`
  display: grid;
  ${grid}
  ${color}
  ${layout}
  ${size}
  ${space}
  ${position}
`
export default Grid
