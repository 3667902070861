import { useState } from 'react'
import { Box, Span, Grid } from 'src/styled'
import styled from 'styled-components'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from './Input'

const Category = styled(Span)`
  position: relative;
  font-size: 15px;
  border-radius: 3px;
  display: inline-table;
  box-sizing: border-box;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.darkGray};
  border: ${({ theme, selected }) =>
    `2px solid ${
      selected ? theme.colors.lightPrimary : theme.colors.washedOutBlack
    }`};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.lightPrimary};
  }
  padding: 5px;
  margin: 5px;
`

export default function CategorySelection({
  options,
  selected,
  onChange,
  maxSelectedLength,
  extendable,
  onAddOption,
}) {
  const [isExtending, setIsExtending] = useState(false)
  const [additionalItem, setAdditionalItem] = useState('')

  function isSelected(index) {
    if (!selected) return -1
    return selected.some((item) => item === index)
  }

  function onToggleSelection(index) {
    const data = [...selected]
    //Remove the first item in selected options if its has reached maximum amount
    const foundIndex = data.findIndex(
      (item) => item.value === options[index].value,
    )

    if (data.length === maxSelectedLength && foundIndex === -1) {
      data.shift()
    }
    foundIndex === -1
      ? data.push(options[index].value)
      : data.splice(foundIndex, 1)
    onChange(data)
  }

  function appendOption() {
    onAddOption(additionalItem)
    setAdditionalItem('')
    setIsExtending(false)
  }

  return (
    <Box>
      {options.map((option, index) => (
        <Category
          key={index}
          selected={isSelected(option.value)}
          onClick={() => onToggleSelection(index)}
        >
          {option.label}
        </Category>
      ))}
      {extendable && !isExtending && (
        <Category onClick={() => setIsExtending(true)}>
          <FontAwesomeIcon icon={faPlus} size={'1x'} />
        </Category>
      )}
      {isExtending && (
        <Category>
          <Grid gridAutoFlow="column" gridGap={10}>
            <FontAwesomeIcon
              color={'red'}
              icon={faTimes}
              onClick={() => setIsExtending(false)}
            />

            <Input
              type={'text'}
              label={'New item...'}
              value={additionalItem}
              onChange={(e) => setAdditionalItem(e.currentTarget.value)}
            />

            <FontAwesomeIcon
              size={'1x'}
              icon={faPlus}
              color={'#1ec66c'}
              onClick={appendOption}
            />
          </Grid>
        </Category>
      )}
    </Box>
  )
}
