import { ReactNode, FC } from 'react'
import styled from 'styled-components'
import { Box, Header, Flex } from 'src/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { devices } from 'src/theme/device'

const Container = styled(Flex)`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled(Flex)`
  background: white;
  border-radius: 10px;
  margin: auto;
  position: relative;
  width: 500px;
  height: fit-content;
  position: fixed;
  flex-direction: column;
  padding: 20px;
  z-index: 2;
  top: 35%;
  ${devices.tablet} {
    max-width: 95%;
  }
`

const Close = styled(Box)`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
`

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Close onClick={onClick}>
    <FontAwesomeIcon icon={faTimes} />
  </Close>
)

const Background = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
`

export interface ModalProps {
  children: ReactNode
  onClose: () => void
  title: string
}

const Modal: FC<ModalProps> = ({ title, children, onClose }) => {
  return (
    <>
      <Container>
        <Wrapper>
          <Flex mb={3}>
            <Header as="h3"> {title} </Header>
            <CloseButton onClick={onClose} />
          </Flex>

          {children}
        </Wrapper>
        <Background onClick={onClose} />
      </Container>
    </>
  )
}

export default Modal
