import styled from 'styled-components'
import { Text } from 'src/styled'
import generateID from 'src/utils/generateId'

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 15px;
`

const Textarea = styled.textarea`
  width: 100%;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  color: black;
  transition: 0.2s ease-out;
  width: 100%;
  resize: vertical;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:focus + label {
    color: ${({ theme }) => theme.colors.primary};
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  &:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
`

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.2s ease-out;
  transform-origin: left top;
  pointer-events: none;
`

const ErrorLabel = styled(Text)`
  font-size: 12px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.danger};
`

export default function TextareaField({
  type,
  label,
  cols,
  rows,
  value,
  onChange,
  error,
  tip,
  ...rest
}) {
  const id = generateID()
  return (
    <Container>
      <Textarea
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        cols={cols}
        rows={rows}
        placeholder=" " //Do NOT remove this (it makes the label animation working)
        {...rest}
      />
      <Label>{label}</Label>

      {tip && (
        <TipWrapper>
          <Tooltip tip={tip} />
        </TipWrapper>
      )}

      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Container>
  )
}
