import Head from 'next/head'

export default function SEO({
  description = 'Найдите вакансии (предложения о работе) в Центральной Азии (Узбекистан, Таджикистан) и многих других странах.',
  title,
  image = 'https://firebasestorage.googleapis.com/v0/b/bakor-bf9ff.appspot.com/o/bakor-logo.png?alt=media&token=b1dcea73-4979-4c0a-9f64-93888789d52f',
}) {
  const structureddData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'bakor',
    mainEntityOfPage: 'https://www.bakor.org/',
    url: 'https://www.bakor.org/',
    sameAs: ['https://www.linkedin.com/company/bakor'],
    email: 'help@bakor.org',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+48 576 203 572',
      contactType: 'customer support',
      contactOption: 'TollFree',
      areaServed: 'UZ',
    },
    foundingDate: '2020',
    foundingLocation: 'Tashkent, Uzbekistan',
    member: {
      '@type': 'Person',
      name: 'Orif Milod',
      jobTitle: 'CEO',
      nationality: 'Tajikistan',
      url: 'https://www.linkedin.com/in/milodorif/',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      reviewCount: '48',
      author: {
        '@type': 'Organization',
        name: 'Clutch',
        url: 'https://clutch.co/profile/bakor',
      },
    },
    logo: 'https://firebasestorage.googleapis.com/v0/b/bakor-bf9ff.appspot.com/o/bakor-logo.png?alt=media&token=b1dcea73-4979-4c0a-9f64-93888789d52f',
    numberOfEmployees: 10,
  }

  return (
    <Head>
      <title>Бакор | {title}</title>
      <meta charSet="UTF-8" />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta content="https://bakor.org" property="og:url"></meta>
      <meta content="website" property="og:type"></meta>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1 , maximum-scale=1.0,user-scalable=0"
      />
      <meta property="og:image" content={image} />
      <meta name="theme-color" content="#ff4081" />
      <meta content="Бакор" property="og:title" />
      <meta name="keywords" content="Работа" />
      <meta property="og:locale:alternate" content="ru_RU" />
      <meta property="og:locale:alternate" content="en_EN" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="robots" content="index, follow" />

      {/* <script type="application/ld+json">{structureddData}</script> */}
    </Head>
  )
}
