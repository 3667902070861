import React from 'react'
import styled from 'styled-components'
import { Button, Box } from 'src/styled'
import theme from 'src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const ListBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-radius: 5px;
  border: 1px solid ${theme.colors.secondary};
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  display: none;
  background: white;
  overflow: hidden;
`

const List = styled.ul`
  list-style: none;
  font-size: 14px;
  width: 100%;
`
const Item = styled.li`
  word-wrap: break-word;
  cursor: pointer;
  display: block;
  padding: 10px;
  &:hover {
    background: ${theme.colors.lightGray};
  }
  ${({ borderBtm }) =>
    borderBtm && `border-bottom: 1px solid ${theme.colors.secondary}`};
  color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.darkGray};
`

const Wrapper = styled(Box)`
  &:focus-within,
  &:focus,
  &:active {
    ${ListBox} {
      display: block !important;
    }
    svg {
      transform: rotateZ(-180deg);
      color: ${theme.colors.primary};
    }
  }
  svg {
    transition: 0.2s ease-in;
  }
`

const Dropdown = ({
  label,
  options,
  value,
  onChange,
  dropDownIcon = faChevronDown,
}) => {
  const onFocus = (e) => e.target.focus()

  return (
    <Wrapper>
      <Button variant={'primary-reverse'} onClick={onFocus}>
        {label} <FontAwesomeIcon icon={dropDownIcon} />
      </Button>

      <ListBox>
        <List>
          {options &&
            options.map((option, index) => (
              <Item
                key={index}
                borderBtm={index < options.length - 1}
                onClick={() => onChange(option)}
                active={option === value}
              >
                {option}
              </Item>
            ))}
        </List>
      </ListBox>
    </Wrapper>
  )
}

export default Dropdown
