import { Text, Span } from 'src/styled'
import styled from 'styled-components'

const Header = styled(Text)`
  position: relative;
  text-align: center;
  z-index: 1;
  :before {
    border-top: ${({ theme }) => `2px solid ${theme.colors.lightPrimary}`};
    content: '';
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  ${Span} {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #fff;
    padding: 0 15px;
  }
`

export default function Seperator({ label, ...rest }) {
  return (
    <Header {...rest}>
      <Span>{label}</Span>
    </Header>
  )
}
