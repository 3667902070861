import { useState, useEffect } from 'react'
import Input from './Input.tsx'
import { Box, Span } from 'src/styled'

import styled from 'styled-components'
import theme from 'src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const List = styled.ul`
  height: fit-content;
  max-height: 200px;
  width: 100%;
  position: absolute;
  top: 88%;
  left: 0;
  right: 0;
  z-index: 2;
  background: white;

  list-style-type: none;
  > li {
    padding: 10px 20px;
    border-bottom: 1px solid ${theme.colors.lightGray};
    &:hover {
      background: ${theme.colors.lightGray};
      cursor: pointer;
    }
  }
  border: 1px solid ${theme.colors.washedOutBlack};
  transition: 0.2s ease-out;
  border-radius: 0 0 5px 5px;
  overflow: auto;
  display: none;
  ${({ active }) =>
    active &&
    `
    &:hover {
      border-color: ${theme.colors.primary};
      border-top-color: ${theme.colors.washedOutBlack};
      display: initial;
    }
  `}
`

const Container = styled(Box)`
  position: relative;

  &:focus-within {
    ${List} {
      border-color: ${theme.colors.primary};
      border-top-color: ${theme.colors.washedOutBlack};
      display: initial;
    }
  }
`

const ClearButton = styled(Span)`
  position: absolute;
  margin: auto;
  top: 3px;
  bottom: 0;
  right: 5px;
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.darkGray};
`

const Datalist = ({
  onChange,
  value,
  options,
  placeholder,
  error,
  defaultValue = '',
}) => {
  const [active, setActive] = useState(false)
  const [search, setSearch] = useState(value)

  useEffect(() => {
    setSearch(getValueLabel(options, value))
  }, [value])

  const searchValue = (item, filter) => {
    return item.value.toLowerCase().search(filter.toLowerCase()) !== -1
  }

  function onSelect(option) {
    onChange(option.value)
    setSearch(option.label)
    setActive(false)
  }

  function filterOptions(filterValue, options) {
    if (!filterValue || !filterValue.trim()) return options
    return options.filter((item) => searchValue(item, filterValue))
  }

  function getValueLabel(options, selected) {
    const item = options.find((option) => option.value === selected)
    return item ? item.label : defaultValue
  }

  return (
    <Container isActive={active}>
      <Input
        label={placeholder}
        value={active ? search : getValueLabel(options, value)}
        error={error}
        onFocus={() => setActive(true)}
        onBlur={() => setSearch('')}
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
      />

      {value && (
        <ClearButton onClick={() => onChange('')}>
          <FontAwesomeIcon icon={faTimes} />
        </ClearButton>
      )}

      {filterOptions(search, options) && (
        <List active={active}>
          {filterOptions(search, options).map((option) => (
            <li onClick={() => onSelect(option)}>{option.label}</li>
          ))}
        </List>
      )}
    </Container>
  )
}

export default Datalist
