import Link from 'next/link'
import styled from 'styled-components'
import { Header as StyledHeader, Flex } from 'src/styled'
import { devices } from 'src/theme/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SideNav = styled(Flex)`
  flex-direction: column;
  padding: 0;
  flex: 1;
`

const Header = styled(StyledHeader)`
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '30px')};
  margin-top: 30px;
  ::after {
    width: 80%;
    height: 1px;
    content: '';
    display: table;
    margin: 20px auto 0;
    background: rgba(0, 0, 0, 0.3);
  }
`

const Anchor = styled(Link)`
  color: ${({ active, theme }) => (active ? 'white' : theme.colors.darkGray)};
  padding: 20px;
  background: ${({ active, theme }) => active && theme.colors.primary};
  &:hover {
    background: ${({ active, theme }) =>
      active ? theme.colors.shadowedPrimary : theme.colors.washedOutBlack};
  }

  svg {
    margin-right: 10px;
    width: 18px !important;
    height: 18px !important;
  }

  display: ${({ mobileOnly }) => mobileOnly && 'none'};

  ${devices.laptop} {
    display: ${({ mobileOnly }) => mobileOnly && 'inline'};
  }
`

const Item = ({ href, label, title, onClick, icon, iconSize, ...rest }) => (
  <Anchor onClick={onClick} title={title} {...rest} href={href}>
    {icon && <FontAwesomeIcon icon={icon} size={iconSize} />} {label}
  </Anchor>
)

const Button = ({ label, title, onClick, icon, iconSize = '1x', ...rest }) => (
  <Anchor onClick={onClick} title={title} {...rest}>
    {icon && <FontAwesomeIcon icon={icon} size={iconSize} />} {label}
  </Anchor>
)

SideNav.Item = Item
SideNav.Button = Button
SideNav.Header = Header

export default SideNav
