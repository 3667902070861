import { Flex } from 'src/styled'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faUser,
  faQuestion,
  faBriefcase,
  faBuilding,
  faUserTie,
  faSignOutAlt,
  faFileSignature,
  faUserEdit,
  faBullhorn,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import { SideNav } from 'src/components/common'
import { signOut } from 'src/redux/reducers/user'
import userTypes from 'src/constant/userTypes'

const CloseButton = styled(FontAwesomeIcon)`
  top: 10px;
  right: 10px;
  position: absolute;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Slider = styled(Flex)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: -450px;
  min-width: 300px;
  min-height: 100vh;
  z-index: 3;
  background: white;
  flex-direction: column;
  transition: 0.5s ease-in-out;
`

const SignOutItem = styled(SideNav.Item)`
  margin-top: auto;
`

function NavSlider({ toggleSlider }) {
  const { isAuthenticated, userData } = useSelector((state) => state.user)
  const hasComapnyAccess = userData && userTypes.COMPANY === userData.userType
  const hasPublisherAccess =
    userData && userTypes.PUBLISHER === userData.userType

  const dispatch = useDispatch()

  function onSignOut() {
    dispatch(signOut())
  }

  return (
    <Slider id="slider">
      <CloseButton icon={faTimes} size={'2x'} onClick={toggleSlider} />
      <SideNav.Header> Бакор </SideNav.Header>

      <SideNav>
        {/* Mobile Only Items */}
        <SideNav.Item
          mobileOnly
          href={'/'}
          label={'Вакансии'}
          onClick={toggleSlider}
          icon={faBriefcase}
        />
        <SideNav.Item
          mobileOnly
          href={'/company/all'}
          label={'Компании'}
          onClick={toggleSlider}
          icon={faBuilding}
        />
        {/* <SideNav.Item
          href={'/offers/new'}
          label={'Разместить вакансию'}
          onClick={toggleSlider}
          icon={faBullhorn}
        /> */}
        {!isAuthenticated && (
          <SideNav.Item
            href={'/auth/sign-in'}
            label={'Войти / зарегистрироваться'}
            onClick={toggleSlider}
            icon={faUser}
          />
        )}
        {isAuthenticated && userData && (
          <SideNav.Item
            href={`/`}
            label={userData.email}
            onClick={toggleSlider}
            icon={faUser}
          />
        )}
        {isAuthenticated && (hasPublisherAccess || hasComapnyAccess) && (
          <>
            <SideNav.Item
              href={'/admin/offers'}
              label={'Панель админа'}
              onClick={toggleSlider}
              icon={faUserEdit}
            />
          </>
        )}
        {!hasComapnyAccess && !hasPublisherAccess && (
          <SideNav.Item
            href={'/company/register'}
            label={'Зарегистрировать компанию'}
            onClick={toggleSlider}
            icon={faUserTie}
          />
        )}
        <SideNav.Item
          href={'/about'}
          label={'О нас'}
          onClick={toggleSlider}
          icon={faQuestion}
        />

        <SideNav.Item
          href={'/contact'}
          label={'Связаться со службой поддержки'}
          icon={faFileSignature}
          onClick={toggleSlider}
        />

        {isAuthenticated && (
          <SignOutItem
            href={'/'}
            label={'Выйти'}
            mt={'auto'}
            icon={faSignOutAlt}
            onClick={onSignOut}
          />
        )}
      </SideNav>
    </Slider>
  )
}
export default NavSlider
