import Link from 'next/link'
import styled from 'styled-components'
import { color, layout, space, size, typography } from 'styled-system'

const Anchor = styled(Link)`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

const StyledLink = ({ href, children, ...rest }) => (
  <Anchor {...rest} href={href}>
    {children}
  </Anchor>
)

export default StyledLink
