import styled from 'styled-components'
import { Box, Text, Flex } from 'src/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { getIndustryTrans } from 'src/constant/industries'

// Default styles for icons located on map
const ICON_DEFAULT_BORDER_RADIUS = '50%'
const ICON_DEFAULT_WIDTH = '10px'

const Wrapper = styled(Flex)`
  border-radius: ${({ isDimensionsDefined }) =>
    isDimensionsDefined ? '20px' : ICON_DEFAULT_BORDER_RADIUS};
  height: 30px;
  width: ${({ isDimensionsDefined }) =>
    isDimensionsDefined ? 'fit-content' : ICON_DEFAULT_WIDTH};
  margin: auto;
  padding: 0 10px;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;

  ${({ isIconFocused, isDimensionsDefined }) =>
    !isDimensionsDefined &&
    isIconFocused &&
    `animation: bounce .5s infinite alternate;
     -webkit-animation: bounce .5s infinite alternate;
     animation-timing-function: cubic-bezier( 
     .5, 0.05, .3, .5);
    
    @keyframes bounce {
      from {
        transform: translate3d(0, 0, 0); 
      }
      to {
        transform: translate3d(0, 15px, 0); 
      }`}
`

const Container = styled(Box)`
  text-align: center;
  ${Wrapper} {
    transition: 0.2s ease-in-out;
  }
  &:hover {
    cursor: pointer;
    ${Wrapper} {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.16);
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
    filter: grayscale(0.9)
  `}
`

const Badge = styled(Box)`
  position: absolute;
  height: 15px;
  width: fit-content;
  min-width: 15px;
  top: 0px;
  left: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  font-size: 11px;
  padding: 1px;

  ${({ isIconFocused, isDimensionsDefined }) =>
    !isDimensionsDefined &&
    isIconFocused &&
    `top: -5px;
       left: 20px;
   `}
`

const Label = styled(Text)`
  text-align: center;
  font-size: 10px;
  margin-left: 10px;
`

export default function Category({
  category,
  disabled,
  iconClickHandler,
  noLabel,
  companyOffers,
  iconPosition,
}) {
  const router = useRouter()
  const { query } = router

  const isAnyCompanyOfferFocused =
    companyOffers &&
    companyOffers.some((offer) => {
      return offer.id === query.id
    })

  const isIconFocused = query.id && isAnyCompanyOfferFocused
  const isDimensionsDefined = !!iconPosition // If iconPosition's value is falsy, then it describes location (Map)

  return (
    <Container disabled={disabled}>
      <Wrapper
        isIconFocused={isIconFocused}
        isDimensionsDefined={isDimensionsDefined}
        bg={category.color}
        {...(iconClickHandler && {
          onClick: () => iconClickHandler(category.name),
        })}
      >
        {companyOffers && companyOffers.length > 1 && (
          <Badge
            isIconFocused={isIconFocused}
            isDimensionsDefined={isDimensionsDefined}
          >
            {companyOffers.length}
          </Badge>
        )}

        <FontAwesomeIcon icon={category.icon} size={'1x'} />

        {!noLabel && category.name && (
          <Label fontSize={12}>{getIndustryTrans('ru', category.name)}</Label>
        )}
      </Wrapper>
    </Container>
  )
}
