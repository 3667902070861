import isEng from 'src/utils/inEnglish.ts'

export const authTypes = {
  email: 'EmailAndPassword',
  gmail: 'Gmail',
  facebook: 'Facebook',
  github: 'Github',
}

export const dates = (locale, difference, period) => {
  const isEnglish = isEng(locale)
  const floorPeriod = Math.floor(difference / period)
  return {
    day: {
      periodDay: 1,
      descriptionDay: isEnglish ? 'day' : 'дн.',
    },
    week: {
      periodWeek: 7,
      descriptionWeek: isEnglish ? 'week' : 'нeд.',
    },
    month: {
      periodMonth: 30,
      descriptionMonth: isEnglish ? 'month' : 'мес.',
    },
  }
}

export const time = (locale) => {
  const isEnglish = isEng(locale)
  return {
    new: isEnglish ? 'New' : 'Новинка',
    ago: isEnglish ? 'ago' : 'назад',
  }
}
