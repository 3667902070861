import styled from 'styled-components'
import { Span, Text } from 'src/styled'
import Tooltip from './Tooltip'

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`

const Select = styled.select`
  width: 100%;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  color: black;
  transition: 0.2s ease-out;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus + label {
    color: ${({ theme }) => theme.colors.primary};
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
  &:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
`

const ErrorLabel = styled(Text)`
  font-size: 12px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.danger};
`

const TipWrapper = styled(Span)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`

export default function SelectInput({
  placeholder,
  options,
  onChange,
  value,
  error,
  tip,
  ...rest
}) {
  return (
    <Container>
      <Select onChange={onChange} value={value} {...rest}>
        <option disabled selected>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}> {option.label} </option>
        ))}
      </Select>

      {tip && (
        <TipWrapper>
          <Tooltip tip={tip} />
        </TipWrapper>
      )}

      {error && <ErrorLabel> {error} </ErrorLabel>}
    </Container>
  )
}