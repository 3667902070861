import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { useDispatch, useSelector } from 'react-redux'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { fetchUserData } from 'src/redux/reducers/user'

import Navbar from '../navbar'
import { Flex } from 'src/styled'
import theme from 'src/theme'
import styled, { ThemeProvider } from 'styled-components'
import { __prod__ } from 'src/constant'
import { Analytics } from '@vercel/analytics/react'

const Page = styled(Flex)`
  padding-top: 75px;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  flex: 1;
`

const Main = styled(Flex)`
  flex: 1;
  min-height: 100vh;
  height: fit-content;
  flex-direction: column;
`

export default function App({ children }) {
  const dispatch = useDispatch()
  const { isAuthenticating, isAuthenticated } = useSelector(
    (state) => state.user,
  )

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (authUser) => {
      if (!isAuthenticating && !isAuthenticated && authUser) {
        dispatch(fetchUserData())
      }
    })
    if (__prod__) hotjar.initialize(2023741)

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Main>
        <Navbar />
        <Page {...children.props}>
          {children} <Analytics />
        </Page>
      </Main>
    </ThemeProvider>
  )
}
