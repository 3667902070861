import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'

import { Grid, Span } from 'src/styled'
import { Input } from 'src/components/common'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import Checkbox from './Checkbox'
import Datalist from './Datalist'
import { currencyOptions } from 'src/modules/offers/constants'
import { devices } from 'src/theme/device'

const Container = styled(Grid)`
  grid-gap: 10px;
  grid-template-columns: 1fr 30px 1fr 150px 200px;
  ${devices.tablet} {
    grid-template-columns: 1fr 30px 1fr;
    > .container {
      grid-column: 3/4;
    }
  }
`

interface IProps {
  minSalary: number
  maxSalary: number
  maxSalaryError: string
  minSalaryError: string
  setState: (prevState: object) => object
  currency: string
  currencyError: string
}

const SalaryRange: FC<IProps> = ({
  minSalary,
  maxSalary,
  minSalaryError,
  maxSalaryError,
  setState,
  currency,
  currencyError,
}) => {
  const [disableSalary, setDisableSalary] = useState(false)

  function handleDisableCheck(value: boolean) {
    if (value) {
      setState((prevState: object) => ({
        ...prevState,
        minSalary: 0,
        maxSalary: 0,
        currency: 'n/a',
      }))
    }
    setDisableSalary(value)
  }

  function onHandleChange(event: ChangeEvent<HTMLInputElement>) {
    //Min salary cannot be larger than the max salary
    //TODO: Fix this, write better code

    const { name, value } = event.target

    if (name === 'minSalary' && Number(value) > maxSalary) {
      setState((prevState: object) => ({
        ...prevState,
        minSalary: isNaN(Number(value)) ? '' : Number(value),
        maxSalary: isNaN(Number(value)) ? '' : Number(value),
      }))
    } else {
      setState((prevState: object) => ({
        ...prevState,
        [name]: isNaN(Number(value)) ? '' : Number(value),
      }))
    }
  }

  return (
    <Container>
      <Input
        name={'minSalary'}
        value={minSalary}
        onChange={onHandleChange}
        error={minSalaryError}
        label={'Зарплата до'}
        tip={'Добавьте минимальную сумму зарплаты, которая будет выплачиваться'}
        disabled={disableSalary}
      />

      <Span my={'auto'} color="purplish" textAlign="center" mb={20}>
        <FontAwesomeIcon icon={faArrowCircleRight} />
      </Span>

      <Input
        name={'maxSalary'}
        value={maxSalary}
        onChange={onHandleChange}
        error={maxSalaryError}
        label={'Зарплата от'}
        disabled={disableSalary}
      />

      <Datalist
        value={currency}
        onChange={(value: string) =>
          setState((prevState: object) => ({
            ...prevState,
            currency: value,
          }))
        }
        error={currencyError}
        placeholder={'Валюта'}
        options={currencyOptions('ru')}
      />

      <Checkbox
        label={'Неизвестная зарплата'}
        checked={disableSalary}
        onChange={handleDisableCheck}
      />
    </Container>
  )
}

export default SalaryRange
